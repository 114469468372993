import * as React from "react";
import xml2js from "xml2js";
import Iframe from "react-iframe";
import { Box, Container, Grid, Paper } from "@mui/material";

//curl https://us-central1-minae-pages-mediation-server.cloudfunctions.net/api/all
//ここから
const FEED_URL =
  "https://us-central1-minae-pages-mediation-server.cloudfunctions.net/api/all";

function Notes() {
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    let ret = [];
    fetch(FEED_URL).then(function (response) {
      response.text().then(function (text) {
        xml2js.parseString(text, function (error, result) {
          if (error) {
            console.log(error.message);
          } else {
            let count = 0;
            for (const i of result.rss.channel[0].item) {
              ret.push(i);
              count++;
              if (6 - 1 < count) break;
            }
            setArticles(ret);
          }
        });
      });
    });
  }, []);

  return (
    <Container>
      <Grid container spacing={1}>
        {
          articles.map((i) => (<Grid item xs={4} key={i.guid[0]}><Iframe
            class="note-embed"
            src={"https://note.com/embed/notes/" + (i.guid[0]).substring(32, 45)}
            frameBorder="0"
            height="220px"
            width="100%"
          ></Iframe></Grid>
          ))
        }
        <script
          async
          src="https://note.com/scripts/embed.js"
          charSet="utf-8"
        ></script>
      </Grid >
    </Container>
  );
}

export default Notes;
