import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import Grid from "@mui/material/Grid";
import MinaeImage from "../../assets/minae.JPG";

function ProductSmokingHero() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          プロフィール
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} order={{ xs: 2, sm: 1 }}>
            <Box>
              <Box width="100%" style={{ margin: "auto" }}>
                <Typography variant="body1" sx={{ mt: 3 }}>
                  わくわくする学びを伝えたい！
                </Typography>
                <Typography variant="body1" sx={{ mb: 3 }}>
                  STEAM教育の専門家
                </Typography>
                <Typography variant="h4" mb={2}>
                  鈴木美苗
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  合同会社学生ギルド 代表社員
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  株式会社鈴木時計舗 自営業
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  トヨタ看護専門学校 非常勤講師
                </Typography>
                <Typography variant="subtitle2" color="gray" sx={{ my: 0 }}>
                  修士（教育学）（愛知教育大学）
                </Typography>
                <Typography variant="body1" sx={{ my: 3 }}>
                  横断的実践的体験的創造的学びSTEAM教育の本来あるべき姿の実現に向け、教育コンサルティングからキャリア支援、不登校支援、個性のつよい子どもへの対応、新しい時代に必要な力、子育て、起業、組織作り、チームの在り方について伝えています。
                </Typography>
                <Typography variant="body1" sx={{ my: 3 }}>
                  プログラミング教室を主軸とした横断的学びを提供する場としてSTREAMというオンラインスクールを学生と共に運営しています。不登校の子どものリベンジキャンプ・発達障がいの子どもの練習として始めた野外キャンプは学生主導で多様な出会いを作り出す場に成長しました。新しい技術に触れられるテックイベント、安心して自己開示できるオンラインコミュニティー、出会いと出番を作るフェスも主宰しています。
                </Typography>
                <Typography variant="body1" sx={{ my: 3 }}>
                  教育学（専門：不登校・生活指導）を専攻し、私自身が学校に馴染めなかった時期もあることから教育について深く考えてきました。三人の子どもたちの母親でもあり、創業111年の時計屋を夫と共に経営もするなか、志を同じくする学生のメンバーと出会うことができ、起業しました。チームを作ることで、それまで一人では決してできなかったことを実現している今だからこそ伝えられる思いを伝えていきます。
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} mt={3} order={{ xs: 1, sm: 2 }}>
            <div style={{ textAlign: "center" }}>
              <img src={MinaeImage} width="80%" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductSmokingHero;
