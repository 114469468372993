import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";
import Notes from "./Notes";

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "secondary.light", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 10,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 5 }}>
          お知らせ
        </Typography>
        <Notes />
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="https://note.com/gakuseiguild"
        >
          Noteへ（外部リンク）
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
