import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import TextField from "../components/TextField";

function Copyright() {
  return (
    <React.Fragment>
      <Box
        color="inherit"
        href="https://gakuseiguild.co.jp/"
        position="center"
      >
        <div style={{ textAlign: "center" }}>
          {"© "}
          <Link
            sx={{ mb: 3 }}
            color="inherit"
            href="https://gakuseiguild.co.jp/"
            position="center"
          >
            gakuseiguild.inc
          </Link>{" "}
          {new Date().getFullYear()}
        </div>
      </Box>
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "warning.main",
  mr: 1,
  "&:hover": {
    bgcolor: "warning.dark",
  },
};

const LANGUAGES = [
  {
    code: "en-US",
    name: "English",
  },
  {
    code: "fr-FR",
    name: "Français",
  },
];

export default function AppFooter() {
  return <Copyright />;
}
